footer.content-info {
  background-color: #fff;
  font-size: 16px;
  padding: 30px 0;

  section.widget_nav_menu {
    ul.menu {
      @extend .list-inline, .font-oswald, .text-uppercase;

      li {
        padding-right: 11px;

        &:after {
          content: '|';
          display: inline-block;
          margin-left: 8px;
          margin-right: 0;
          font-size: 15px;
          position: absolute;
          top: 0;
        }

        &:last-of-type {
          &:after {
            display: none;
          }
        }
      }
    }
  }

  .copyright,
  .colophon {
    font-size: 12px;

    @include breakpoint(992px) {
      text-align: right;
    }
  }
}

nav#oc-nav {
  background: #3e3e3e;
  font-family: $font-family-navigation;
  font-size: 16px;
  position: relative;

  i {
    font-size: 1.2em;
    @extend .fa-fw;
  }

  .phone {
    background-color: $brand-secondary;
    border-bottom: 1px solid #555;
    color: #fff;
    display: block;
    padding: 10px 15px;
    text-decoration: none;
    text-transform: uppercase;
  }

  ul {
    li {
      margin-top: 0;

      a {
        color: #fff;
        border-bottom: 1px solid #555;
        text-transform: uppercase;

        &:hover,
        &:focus {
          color: $brand-secondary;
        }
      }

      &.active {
        a,
        a:hover {
          color: #fff;
        }
      }
    }
  }
}
