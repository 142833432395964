
/* TypoPRO Source Sans Pro ExtraLight */
@font-face {
    font-family:  'TypoPRO Source Sans Pro';
    src:          url('TypoPRO-SourceSansPro-ExtraLight.eot');
    src:          local('*'),
                  url('TypoPRO-SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-SourceSansPro-ExtraLight.woff') format('woff'),
                  url('TypoPRO-SourceSansPro-ExtraLight.ttf') format('truetype');
    font-style:   normal;
    font-weight:  200;
    font-stretch: normal;
    font-variant: normal;
}

