#masthead {
  background-color: #fff;
  position: relative;

  .site-logo {
    margin: 20px auto;
  }

  .hdr-nav {
    text-align: center;

    @include breakpoint(1199px) {
      text-align: right;
    }

    ul#menu-header-navigation {
      font-family: $font-family-navigation;
      font-size: 16px;
      margin-top: 10px;

      li {
        a {
          background-color: $brand-primary;
          color: #fff;
          padding: 10px 20px;
          text-align: center;

          &:hover {
            background-color: $brand-secondary;
            text-decoration: none;
          }
        }

        &:last-child {
          a {
            background-color: $brand-secondary;
          }
        }
      }
    }
  }

  .oc-nav-toggle {
    position: absolute;
    top: 10px;
    right: 10px;
  }

}

header.banner {
  font-family: $font-family-navigation;
  font-size: 16px;
  margin-bottom: 0;

  @include breakpoint(1199px) {
    margin-top: 20px;
  }

  ul.nav.navbar-nav {
    font-size: inherit;

    > li {
      > a {
        @extend .text-uppercase;

        @include breakpoint(1199px) {
          padding: 38px 11px 10px;

          i {
            display: none;
          }
        }
      }

      @include breakpoint(1199px) {
        &.active,
        &.current-page-parent {
          > a {
            padding-top: 10px;

            &:before {
              color: $brand-primary;
              display: block;
              content: "\f13d";
              font-family: "FontAwesome";
              font-size: 22px;
              text-align: center;
              width: 100%;
            }
          }
        }
      }
    }

    ul.dropdown-menu {
      border-width: 3px;
      font-size: inherit;
      padding: 0;

      @include breakpoint(1199px) {
        left: auto !important;
  			right: 50% !important;
        text-align: center;

        li {
          a {
            border-bottom: 3px solid #fff;
            padding: 7px 20px;
          }

          &:last-child {
            a {
              border-bottom-width: 0;
            }
          }
        }
      }
    }
  }
}

.oc-overlay {
  position: fixed;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  display: none;
  z-index: 999999;
  transition: all 225ms ease;
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: fadeIn;

  cursor: pointer;
}

.scotch-is-showing {
  .oc-overlay {
    display: block;
  }
}
