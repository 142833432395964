.boat-wrap {
  @extend .default-content-styling;

  h2 {
    margin-top: 0;
  }

  .details {
    margin-bottom: 20px;
  }

  .featured,
  .gallery {
    a {
      display: block;
      position: relative;
      transition: all .3s ease;

      &:after {
        color: #fff;
        content: "\f067";
        font: 16px "FontAwesome";
        position: absolute;
        bottom: 5px;
        right: 5px;
      }

      img {
        transition: inherit;
      }

      &:hover {
        img {
          opacity: .7;
        }
      }
    }
  }

  .gallery {
    @include make-row(5px);
    margin-top: 5px;

    .photo {
      @include make-xs-column(3, 5px);
    }
  }
}
