
/* Source Sans Pro Black */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-Black.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-Black.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-Black.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-Black.ttf') format('truetype');
    font-style:   normal;
    font-weight:  900;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Black Italic */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-BlackItalic.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-BlackItalic.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-BlackItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  900;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Bold */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-Bold.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-Bold.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-Bold.ttf') format('truetype');
    font-style:   normal;
    font-weight:  bold;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Bold Italic */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-BoldItalic.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-BoldItalic.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-BoldItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  bold;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro ExtraLight */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-ExtraLight.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-ExtraLight.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-ExtraLight.ttf') format('truetype');
    font-style:   normal;
    font-weight:  200;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro ExtraLight Italic */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-ExtraLightItalic.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-ExtraLightItalic.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-ExtraLightItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  200;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Light */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-Light.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-Light.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-Light.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-Light.ttf') format('truetype');
    font-style:   normal;
    font-weight:  300;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Light Italic */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-LightItalic.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-LightItalic.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-LightItalic.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-LightItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  300;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-Regular.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-Regular.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-Regular.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-Regular.ttf') format('truetype');
    font-style:   normal;
    font-weight:  normal;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Italic */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-RegularItalic.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-RegularItalic.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-RegularItalic.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-RegularItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  normal;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Semibold */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-Semibold.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-Semibold.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-Semibold.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-Semibold.ttf') format('truetype');
    font-style:   normal;
    font-weight:  600;
    font-stretch: normal;
    font-variant: normal;
}


/* Source Sans Pro Semibold Italic */
@font-face {
    font-family:  'Source Sans Pro';
    src:          url('../fonts/TypoPRO-SourceSansPro-SemiboldItalic.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-SourceSansPro-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-SourceSansPro-SemiboldItalic.woff') format('woff'),
                  url('../fonts/TypoPRO-SourceSansPro-SemiboldItalic.ttf') format('truetype');
    font-style:   italic;
    font-weight:  600;
    font-stretch: normal;
    font-variant: normal;
}



/* TypoPRO Oswald Regular */
@font-face {
    font-family:  'Oswald';
    src:          url('../fonts/TypoPRO-Oswald-Regular.eot');
    src:          local('*'),
                  url('../fonts/TypoPRO-Oswald-Regular.eot?#iefix') format('embedded-opentype'),
                  url('../fonts/TypoPRO-Oswald-Regular.woff') format('woff'),
                  url('../fonts/TypoPRO-Oswald-Regular.ttf') format('truetype');
    font-style:   normal;
    font-weight:  normal;
    font-stretch: normal;
    font-variant: normal;
}
