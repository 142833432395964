
/* TypoPRO Source Sans Pro Bold */
@font-face {
    font-family:  'TypoPRO Source Sans Pro';
    src:          url('TypoPRO-SourceSansPro-Bold.eot');
    src:          local('*'),
                  url('TypoPRO-SourceSansPro-Bold.eot?#iefix') format('embedded-opentype'),
                  url('TypoPRO-SourceSansPro-Bold.woff') format('woff'),
                  url('TypoPRO-SourceSansPro-Bold.ttf') format('truetype');
    font-style:   normal;
    font-weight:  bold;
    font-stretch: normal;
    font-variant: normal;
}

