.btn {
  font-family: $headings-font-family;
  transition: all .3s ease;
}

.btn-primary {
  border-color: $brand-primary;

  &:hover {
    background-color: $brand-secondary;
    border-color: $brand-secondary;
  }
}
