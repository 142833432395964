* {
  -webkit-font-smoothing: antialiased;
}

body {
  background-image: url('../images/bg-marina.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center top;
}

a {
  transition: all .3s ease;
}

.page-header {
  border-bottom: none;
  margin: 0;

  h1 {
    background-color: #3e3e3e;
    box-shadow: 5px 5px rgba(35, 31, 32, 0.25);
    color: #fff;
    display: inline-block;
    margin: 0;
    padding: 30px;
  }
}

.font-oswald {
  font-family: "Oswald";
}

h3 {
  color: $brand-primary;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.default-content-styling {
  background-color: #fff;
  background-clip: padding-box;
  border: 25px solid rgba(255, 255, 255, .5);
  margin-bottom: 25px;
  padding: 25px;
}
@media (max-width: 991px) {
  .default-content-styling {
    background-color: #fff;
    border: 0 solid rgba(255, 255, 255, .5);
    margin-bottom: 25px;
    padding: 25px;
  }
}
