.modules {
  margin: 25px auto;

  &.mod-bg-primary {
    @extend .default-content-styling;
  }

  &.forward_paths,
  &.page_children {
    .path-wrap {
      box-shadow: 5px 5px rgba(35, 31, 32, 0.25);
      transition: all .3s ease;

      @include breakpoint(0px, 991px) {
        margin-bottom: 20px;
      }

      background-color: #fff;
      border: 2px solid $brand-primary;

      a {
        text-decoration: none;

        .image, .content {
          margin: 10px;
        }

        .image {
          position: relative;

          .headline {
            background-color: $brand-primary;
            background-color: rgba(28, 90, 133, .85);
            display: inline-block;
            padding: 10px;
            position: absolute;
            top: 15px;
            transition: all .3s ease;

            h2 {
              color: #fff;
              margin: 0 30px;
            }
          }
        }

        .content {
          color: $text-color;
        }

        &:hover {
          .headline {
            background-color: $brand-secondary;
            background-color: rgba(41, 129, 189, .85);
          }

          button {
            background-color: $brand-secondary;
            border-color: $brand-secondary;
          }
        }
      }

      &:hover {
        border-color: $brand-secondary;
      }
    }
  }

  &.image_gallery {
    .mod-col {
      margin-bottom: $grid-gutter-width;

      a {
        display: block;
        position: relative;
        transition: all .3s ease;

        &:after {
          color: #fff;
          content: "\f067";
          font: 16px "FontAwesome";
          position: absolute;
          bottom: 5px;
          right: 5px;
        }

        img {
          transition: inherit;
        }

        &:hover {
          img {
            opacity: .7;
          }
        }
      }
    }
  }

  &.simple_forward_paths {
    .path-wrap {
      box-shadow: 5px 5px rgba(35, 31, 32, 0.25);

      @include breakpoint(0px, 991px) {
        margin-bottom: 20px;
      }

      a {
        text-decoration: none;

        img {
          border: 20px solid #fff;
        }

        &:hover {
          button {
            background-color: $brand-secondary;
            border-color: $brand-secondary;
          }
        }
      }
    }
  }
}
